.CalendarDay__selected_span {
  background: #f47d20 !important;
  color: white;
  border: none !important;
  color: white !important;
}

.CalendarDay__selected {
  background: #f47d20 !important;
  color: white !important;
  border: none !important;
}

.CalendarDay__selected:hover {
  background: orange;
  color: white !important;
  border: none !important;
}

.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
  background: #f47d20 !important;
}

.DayPicker_transitionContainer {
  height: 420px !important;
}

.DateRangePicker_picker {
  margin-top: 1.5rem !important;
}

.DateInput input {
  height: 100% !important;
  width: 100% !important;
  background-color: red;
  cursor:pointer;
}



.DateRangePicker .DateRangePicker_1 {
  display: none !important;
}

#nc__ra_ {
  display: none !important;
}

#nc__r66_{
  display: none !important;
}


.custom-validation-message {
  position: absolute;
  background-color: white;
  border: 1px solid red;
  padding: 8px;
  border-radius: 4px;
  color: red;
  font-size: 14px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 10;
  display: none; /* Hide by default */
}

.custom-validation-message::before {
  content: "";
  position: absolute;
  top: -6px; /* Adjust as needed */
  left: 10px; /* Adjust as needed */
  border-width: 6px;
  border-style: solid;
  border-color: transparent transparent red transparent;
}


.hotel-cash-tax{
  max-width: 379px;
}
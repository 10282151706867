@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;

.tooltip {
  @apply invisible absolute left-14 -top-4 -mt-12  text-white
  rounded-full w-44;
}

.has-tooltip .tooltip {
  @apply visible z-20;
}

@layer base {
  [type="text"],
  [type="email"],
  [type="url"],
  [type="password"],
  [type="number"],
  [type="date"],
  [type="datetime-local"],
  [type="month"],
  [type="search"],
  [type="tel"],
  [type="time"],
  [type="week"],
  [multiple],
  textarea,
  select {
    @apply bg-white dark:bg-neutral-900 border-neutral-200 dark:border-neutral-700 rounded-full focus:ring-indigo-500 
    focus:border-indigo-500 block w-full sm:text-sm text-neutral-900 dark:text-neutral-200;
  }
}

.FormGroup {
  margin: 0 15px 20px;
  padding: 0;
  border-style: none;
  background-color: #7795f8;
  will-change: opacity, transform;
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
    inset 0 1px 0 #829fff;
  border-radius: 4px;
}

.FormRow {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 15px;
  border-top: 1px solid #819efc;
}

.StripeElement--webkit-autofill {
  background: transparent !important;
}

.StripeElement {
  width: 100%;
  padding: 11px 15px 11px 0;
}

ol li {
  list-style-type: decimal;
}
ul li {
  list-style-type: disc;
}

.nc-Header ul li {
  list-style-type: none;
}

.glide__track ul li,
.nc-Footer ul li,
.nc-SectionOurFeatures ul li {
  list-style-type: none;
}

.CalendarDay__blocked_calendar,
.CalendarDay__default.CalendarDay__blocked_out_of_range,
.CalendarDay__default.CalendarDay__blocked_out_of_range:active,
.CalendarDay__default.CalendarDay__blocked_out_of_range:hover {
  cursor: default;
  border-width: 1px;
  border-color: transparent;
  --tw-text-opacity: 1;
  color: rgba(var(--c-neutral-300), var(--tw-text-opacity));
}

.dark .CalendarDay__blocked_calendar,
.dark .CalendarDay__default.CalendarDay__blocked_out_of_range,
.dark .CalendarDay__default.CalendarDay__blocked_out_of_range:active,
.dark .CalendarDay__default.CalendarDay__blocked_out_of_range:hover {
  --tw-text-opacity: 1;
  color: rgba(var(--c-neutral-500), var(--tw-text-opacity));
}

.DateRangePickerInput_clearDates {
  @apply !absolute !z-10 !w-5 !h-5 lg:w-6 lg:h-6 !text-sm !bg-white !text-black dark:!text-white dark:!bg-neutral-800 !rounded-full !flex !items-center 
  !justify-center !right-1 lg:right-3 !top-1/2 !transform -translate-y-1/2;
}

.DateRangePickerInput_clearDates_svg {
  @apply bg-white dark:text-white dark:bg-neutral-800 rounded-full text-black;
}

.DayPicker_transitionContainer
  .DayPicker_transitionContainer_1
  .DayPicker_transitionContainer__horizontal
  .DayPicker_transitionContainer__horizontal_2 {
  height: 300px !important;
}

.cky-btn-revisit-wrapper .cky-revisit-bottom-left {
  display: none !important;
}


.disable-scroll {
  -ms-overflow-style: none !important; /* for Internet Explorer, Edge */
  scrollbar-width: none !important; /* for Firefox */
  overflow-y: scroll !important;
}
.disable-scroll::-webkit-scrollbar {
  display: none !important; /* for Chrome, Safari, and Opera */
}